import { Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import styles from "./ModelSelect.module.css";

interface IOption {
  label: string;
  value: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type ComponentWithStyle<P = {}> = React.ComponentType<P & { style?: React.CSSProperties }>;

interface IProps {
  options: IOption[];
  Icon: ComponentWithStyle;
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

function ModelSelect(props: IProps) {
  const { value, onChange, disabled, options, Icon } = props;
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const valueLabel = options.find((o) => o.value === value)?.label;

  const handleOptionClick = (optionValue: string) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ display: "flex", margin: 8 }}>
      <div ref={selectRef} style={{ flexShrink: 1, display: "flex", margin: 4, position: "relative" }}>
        <div
          className={styles.select}
          style={{
            flexShrink: 1,
            display: "flex",
            alignItems: "center",
            padding: 8,
            borderRadius: 8,
            cursor: "pointer",
          }}
          onClick={() => {
            if (!disabled) {
              setIsOpen(!isOpen);
            }
          }}
        >
          <Icon style={{ marginRight: 8 }} />
          <Text component="span" weight={600}>
            {valueLabel}
          </Text>
          {!disabled && <IconChevronDown style={{ marginLeft: 4 }} />}
        </div>
        {isOpen && (
          <div
            className={styles.dropdown}
            style={{
              position: "absolute",
              top: "100%",
              width: 200,
              left: 0,
              right: 0,
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: 8,
              zIndex: 1000,
              marginTop: 4,
            }}
          >
            {options.map((option) => (
              <div
                key={option.value}
                className={styles.option}
                style={{
                  padding: "8px 16px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleOptionClick(option.value)}
              >
                <Text component="span">{option.label}</Text>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ModelSelect;
