import { ActionIcon, Box, Button, Container, FileButton, Flex, Loader, Select, Text, Textarea } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconFileFunction, IconPaperclip } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { KeyboardEvent, useEffect, useRef, useState, type ChangeEvent } from "react";
import { AiOutlineSend } from "react-icons/ai";
import { supportedFileTypes } from "../../../Config";
import { APIError } from "../../../errors/APIError";
import { useChatId } from "../../../hooks/useChatId";
import { ChatType, store } from "../../../stores/store";
import { AttachedFile } from "./AttachedFile";
import styles from "./PromptInput.module.scss";

function _PromptInput() {
  const chatId = useChatId();
  const messages = chatId ? store.getChatMessages(chatId) : [];
  const userMessages = messages?.filter((message) => message.role === "user").map((message) => message.content) || [];
  const [userMsgIndex, setUserMsgIndex] = useState(0);
  const [content, setContent] = [store.prompt, store.setPrompt];

  const [contentDraft, setContentDraft] = useState("");
  const [tag, setTag] = [store.tag, store.setTag];
  const uploadReset = useRef<() => void | null>(null);
  useEffect(() => {
    store.getLimits();
  }, []);

  const limits = store.limits;
  const [uploading, setUploading] = [store.uploading, store.setUploading];

  const submit = async () => {
    await store.getCompletion({ prompt: store.prompt, chatId });
  };

  const onUserMsgToggle = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    const { selectionStart, selectionEnd } = event.currentTarget;
    if (
      !["ArrowUp", "ArrowDown"].includes(event.code) ||
      selectionStart !== selectionEnd ||
      (event.code === "ArrowUp" && selectionStart !== 0) ||
      (event.code === "ArrowDown" && selectionStart !== event.currentTarget.value.length)
    ) {
      // do nothing
      return;
    }
    event.preventDefault();

    const newMsgIndex = userMsgIndex + (event.code === "ArrowUp" ? 1 : -1);
    const allMessages = [contentDraft, ...Array.from(userMessages).reverse()];

    if (newMsgIndex < 0 || newMsgIndex >= allMessages.length) {
      // index out of range, do nothing
      return;
    }
    setContent(allMessages.at(newMsgIndex) || "");
    setUserMsgIndex(newMsgIndex);
  };

  const onContentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    setContent(value);
    setContentDraft(value);
    setUserMsgIndex(0);
  };

  const formRef = useRef<HTMLFormElement>(null);

  const chat = chatId ? store.getChatById(chatId) : null;

  const systemPromptOptions = [
    ...store.appConfig.systemPrompts.map((prompt) => ({
      label: prompt.name,
      value: prompt.prompt,
    })),
    {
      label: "Свой промт",
      value: "",
    },
  ];

  return (
    <>
      <Box
        py="lg"
        sx={(theme) => ({
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          [`@media (min-width: ${theme.breakpoints.md})`]: {
            left: 300,
          },
          backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[0],
        })}
      >
        <form
          ref={formRef}
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <Container>
            {(!chatId || !!store.attachedFiles.length) && (
              <div style={{ display: "flex", flexDirection: "column", gap: 8, marginBottom: 16 }}>
                <Flex align={"center"} gap="sm" wrap={"wrap"}>
                  {!chatId && (
                    <div className={styles.chatSettingsWrapper}>
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                        }}
                      >
                        <input
                          type="text"
                          className={styles.validationInput}
                          required
                          value={tag || ""}
                          onChange={() => {}}
                        />
                        <Select
                          required
                          style={{ alignSelf: "end" }}
                          classNames={{ root: styles["tag-selector"] }}
                          width={300}
                          label="Категория запроса"
                          data={["Рутина", "Открытые вопросы и R&D", "Новые идеи"]}
                          value={tag}
                          onChange={(val) => {
                            setTag(val);
                          }}
                        />
                      </div>
                      {store.textModel === "AI_CONTEXT" && (
                        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                          <Select
                            required
                            style={{ marginBottom: 8, flexGrow: 1 }}
                            classNames={{ root: styles["tag-selector"] }}
                            width={300}
                            label="Системный промт"
                            data={systemPromptOptions}
                            value={store.systemPromptOption}
                            onChange={(val) => {
                              store.setSystemPromptOption(val);
                              store.setSystemPrompt(val);
                            }}
                          />
                          <div style={{ position: "relative" }}>
                            <Textarea
                              placeholder="Промт"
                              value={store.systemPrompt || ""}
                              onChange={(event) => {
                                store.setSystemPrompt(event.target.value);
                                store.setSystemPromptOption("");
                              }}
                            ></Textarea>
                            <input
                              type="text"
                              className={styles.validationInput}
                              required
                              value={store.systemPrompt || ""}
                              onChange={() => {}}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </Flex>
                {!!store.attachedFiles.length && (
                  <div className={styles.attachmentsContainer}>
                    {store.attachedFiles.map((file) => (
                      <AttachedFile file={file} />
                    ))}
                  </div>
                )}
              </div>
            )}
            {limits && !!limits.remaining && (
              <Flex gap="sm">
                {(!chat || chat?.canAttachFile) && (
                  <FileButton
                    disabled={uploading}
                    resetRef={uploadReset}
                    onChange={(file) => {
                      if (file) {
                        // Check if the file is not null
                        // Create a new FormData object
                        const formData = new FormData();

                        // Append the file to the FormData object
                        formData.append("file", file);
                        setUploading(true);
                        notifications.show({
                          message: "Выполняется загрузка и обработка документа, пожалуйста подождите...",
                          color: "green",
                        });
                        // Use fetch to send a POST request with the file
                        fetch(`${import.meta.env.VITE_BACKEND_ENDPOINT}/api/files`, {
                          method: "POST",
                          body: formData,
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                          },
                        })
                          .then((response) => {
                            if (response.ok) {
                              return response.json();
                            }
                            if (response.headers.get("content-type")?.includes("application/json")) {
                              return response.json().then((err) => Promise.reject(new APIError(err.error)));
                            }
                            throw new Error("Внутренняя ошибка");
                          })
                          .then((data) => {
                            notifications.show({
                              message: "Файл успешно загружен",
                              color: "green",
                            });
                            store.attachedFiles.push({ filename: file.name, fileId: data.fileId });
                          })
                          .catch((error) => {
                            console.error("Error while uploading file:", error);
                            if (error instanceof APIError) {
                              console.log(error);
                              notifications.show({
                                message: error.message,
                                color: "red",
                              });
                            } else {
                              notifications.show({
                                message: "При обработке вашего файла произошла ошибка",
                                color: "red",
                              });
                            }
                          })
                          .finally(() => {
                            setUploading(false);
                            uploadReset.current?.();
                          });
                      } else {
                        console.error("No file selected");
                        uploadReset.current?.();
                      }
                    }}
                    accept={supportedFileTypes[chat?.type || (store.textModel as ChatType)].join(",")}
                  >
                    {(props) => (
                      <ActionIcon variant="outline" h={44} w={44} {...props}>
                        {uploading ? (
                          <Loader size={"sm"} />
                        ) : store.attachedFiles.length ? (
                          <IconFileFunction />
                        ) : (
                          <IconPaperclip />
                        )}
                      </ActionIcon>
                    )}
                  </FileButton>
                )}
                <Textarea
                  required
                  autoFocus
                  key={chatId}
                  sx={{ flex: 1 }}
                  placeholder={!store.submitting ? "Введите ваше сообщение..." : undefined}
                  autosize
                  minRows={1}
                  maxRows={10}
                  maxLength={limits?.charLimit}
                  value={content}
                  onChange={onContentChange}
                  onKeyDown={async (event) => {
                    if ((event.code === "Enter" || event.code === "NumpadEnter") && !event.shiftKey) {
                      event.preventDefault();
                      const valid = formRef.current?.reportValidity();
                      if (!valid) {
                        return;
                      }
                      submit();
                      setUserMsgIndex(0);
                    } else if (event.code === "ArrowUp") {
                      onUserMsgToggle(event);
                    } else if (event.code === "ArrowDown") {
                      onUserMsgToggle(event);
                    }
                  }}
                />
                <Button h="auto" type="submit" loading={store.submitting || uploading}>
                  {!store.submitting && <AiOutlineSend />}
                </Button>
              </Flex>
            )}
            {limits && (
              <Flex justify="center" mt="xs" ta="center">
                {limits?.remaining ? (
                  <Text size="sm" color="dimmed">
                    Сегодня вам доступно ещё {limits.remaining} запросов.{" "}
                    <span style={{ whiteSpace: "nowrap" }}>
                      Символы:{" "}
                      <span
                        style={{
                          ...(content.length >= limits?.charLimit
                            ? { color: "#c74040", fontWeight: "bold" }
                            : { color: undefined }),
                        }}
                      >
                        {content.length}/{limits?.charLimit}.
                      </span>
                    </span>
                  </Text>
                ) : (
                  <Text size="sm" color="dimmed">
                    Отличная работа! Вы так усердно работали с нейросетью, что исчерпали лимит запросов на сегодня.
                    <br />
                    Отдохните и возвращайтесь завтра за новыми открытиями! Для увеличения лимитов обратитесь в поддержку
                    (<a href="mailto:chatai_support@corp.finam.ru">chatai_support@corp.finam.ru</a>).
                    <br />
                  </Text>
                )}
              </Flex>
            )}
          </Container>
        </form>
      </Box>
    </>
  );
}

export const PromptInput = observer(_PromptInput);
