import { ActionIcon, Header } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconAlignLeft, IconPhoto, IconShare2 } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { useState } from "react";
import { useChatId } from "../../hooks/useChatId";
import { store } from "../../stores/store";
import { ShareChatModal } from "../ShareChatModal";
import ModelSelect from "./ModelSelect/ModelSelect";

const _ChatHeader = () => {
  const chatId = useChatId();
  const chat = chatId ? store.getChatById(chatId) : null;

  const [shareOpened, setShareOpened] = useState(false);
  const matches = useMediaQuery("(min-width:70em)");

  return (
    <>
      <Header height={60} p="xs" className="app-region-drag" style={{ display: "flex" }}>
        {store.appConfig.textModels.length > 1 && chat?.type !== "FLOW" && (
          <ModelSelect
            value={chat ? store.appConfig.textModels.find((x) => x.value === chat.type)?.value || "" : store.textModel}
            disabled={!!chat}
            options={store.appConfig.textModels}
            onChange={store.setTextModel}
            Icon={IconAlignLeft}
          />
        )}
        {store.appConfig.imageModels.length > 1 && chat?.type !== "FLOW" && (
          <ModelSelect
            value={store.imageModel}
            options={store.appConfig.imageModels}
            onChange={store.setImageModel}
            Icon={IconPhoto}
          />
        )}
        {chat && (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: matches ? "auto" : undefined,
            }}
          >
            <ShareChatModal opened={shareOpened} onClose={() => setShareOpened(false)} />
            {!!chat?.canShare && (
              <ActionIcon
                style={{ marginLeft: 32 }}
                loading={false}
                onClick={async () => {
                  setShareOpened(true);
                }}
              >
                <IconShare2 />
              </ActionIcon>
            )}
          </div>
        )}
      </Header>
    </>
  );
};

const ChatHeader = observer(_ChatHeader);
export default ChatHeader;
