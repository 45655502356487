import {
  ActionIcon,
  AppShell,
  Box,
  Burger,
  Button,
  Center,
  MediaQuery,
  Modal,
  Navbar,
  rem,
  ScrollArea,
  TextInput,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconLogout, IconMoonStars, IconPlus, IconSearch, IconSunHigh, IconX } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import ChatHeader from "../components/chat/ChatHeader";
import { Chats } from "../components/chat/Chats";
import { ChatSubscription } from "../components/chat/ChatSubscription";
import { PollModal } from "../components/PollModal/PollModal";
import { useChatId } from "../hooks/useChatId";
import FinamLogo2 from "../icons/FinamLogo2";
import { store } from "../stores/store";
import { trpc } from "../utils/trpc";

const _Layout = () => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const navigate = useNavigate();
  const location = useLocation();
  const search = store.search;
  const chatId = useChatId();
  const [readyForSub, setReadyForSub] = useState(false);
  const [supportOpened, setSupportOpened] = useState(false);

  const chat = chatId ? store.getChatById(chatId) : null;

  const [shareOpened, setShareOpened] = useState(false);

  const border = `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`;
  const [pollOpened, setPollOpened] = useState(localStorage.getItem("poll_2") !== "true");

  const { mutateAsync: logout } = trpc.auth.logout.useMutation();

  useEffect(() => {
    setOpened(false);
  }, [location.pathname]);

  useEffect(() => {
    if (store.connected && store.authorized) {
      setTimeout(() => {
        setReadyForSub(true);
      }, 200);
    } else {
      setReadyForSub(false);
    }
  }, [store.connected, store.authorized]);

  return (
    <>
      <PollModal open={pollOpened} onClose={() => setPollOpened(false)} />
      <AppShell
        className={`${colorScheme}-theme`}
        navbarOffsetBreakpoint="sm"
        navbar={
          <Navbar width={{ md: 300 }} hiddenBreakpoint="md" hidden={!opened}>
            <Navbar.Section className="app-region-drag">
              <Box
                style={{
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottom: border,
                }}
              >
                <Link
                  to="/"
                  className="app-region-no-drag"
                  style={{
                    marginTop: 10,
                    padding: 4,
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FinamLogo2
                    style={{
                      height: 22,
                      color: "#ffc755",
                      display: "block",
                      marginRight: 8,
                    }}
                  />
                  <span
                    style={{
                      fontSize: 20,
                      color: "#ffc755",
                      display: "block",
                      textDecoration: "none",
                    }}
                  >
                    Финам.AI
                  </span>
                </Link>
                <MediaQuery largerThan="md" styles={{ display: "none" }}>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.gray[6]}
                    className="app-region-no-drag"
                    sx={{ position: "fixed", right: 16 }}
                  />
                </MediaQuery>
              </Box>
            </Navbar.Section>
            <Navbar.Section
              sx={(theme) => ({
                padding: rem(4),
                background: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[1],
                borderBottom: border,
              })}
            >
              <Box sx={{ padding: 4 }}>
                <Button
                  fullWidth
                  leftIcon={<IconPlus size={20} />}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Новый чат
                </Button>
              </Box>
            </Navbar.Section>
            <Navbar.Section
              sx={(theme) => ({
                padding: rem(4),
                background: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
                borderBottom: border,
              })}
            >
              <TextInput
                variant="unstyled"
                radius={0}
                placeholder="Поиск"
                value={search}
                onChange={(event) => store.setSearch(event.currentTarget.value.toLowerCase())}
                sx={{ paddingInline: 4 }}
                icon={<IconSearch opacity={0.8} size={20} />}
                rightSection={
                  !!search && (
                    <ActionIcon onClick={() => store.setSearch("")}>
                      <IconX opacity={0.5} size={20} />{" "}
                    </ActionIcon>
                  )
                }
              />
            </Navbar.Section>
            <Navbar.Section grow component={ScrollArea}>
              <Chats search={search} />
            </Navbar.Section>
            <Navbar.Section sx={{ borderTop: border }} p="xs">
              <Modal
                opened={supportOpened}
                onClose={() => {
                  setSupportOpened(false);
                }}
                title="Поддержка"
              >
                <p>
                  Связаться с поддержкой можно по электронной почте{" "}
                  <a href="mailto:chatai_support@corp.finam.ru">chatai_support@corp.finam.ru</a>
                </p>
                <p>Пожалуйста сообщайте нам о всех проблемах и ваших пожеланиях!</p>
              </Modal>
              <Center>
                <Button
                  variant="light"
                  fullWidth
                  onClick={() => {
                    setSupportOpened(true);
                  }}
                >
                  Написать в поддержку
                </Button>
              </Center>
            </Navbar.Section>
            {store.session?.isAdmin && (
              <Navbar.Section sx={{ borderTop: border }} p="xs">
                <Center>
                  <Button
                    variant="light"
                    fullWidth
                    onClick={() => {
                      navigate("/admin");
                    }}
                  >
                    Админ-панель
                  </Button>
                </Center>
              </Navbar.Section>
            )}
            <Navbar.Section sx={{ borderTop: border }} p="xs">
              <Center>
                <Tooltip label={colorScheme === "dark" ? "Светлая тема" : "Тёмная тема"}>
                  <ActionIcon sx={{ flex: 1 }} size="xl" onClick={() => toggleColorScheme()}>
                    {colorScheme === "dark" ? <IconSunHigh size={20} /> : <IconMoonStars size={20} />}
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Выйти">
                  <ActionIcon
                    sx={{ flex: 1 }}
                    size="xl"
                    onClick={() => {
                      logout()
                        .then(() => {
                          localStorage.removeItem("token");
                          store.setAuthorized(false);
                          window.location.reload();
                        })
                        .catch((err) => {
                          console.error(err);
                          notifications.show({
                            title: "Ошибка",
                            message: "Произошла ошибка при выходе",
                            color: "red",
                          });
                        });
                    }}
                  >
                    <IconLogout size={20} />
                  </ActionIcon>
                </Tooltip>
              </Center>
            </Navbar.Section>
          </Navbar>
        }
        header={<ChatHeader />}
        layout="alt"
        padding={0}
      >
        <MediaQuery largerThan="md" styles={{ display: "none" }}>
          <Burger
            opened={opened}
            onClick={() => setOpened((o) => !o)}
            size="sm"
            color={theme.colors.gray[6]}
            className="app-region-no-drag"
            sx={{ position: "fixed", top: 16, right: 16, zIndex: 100 }}
          />
        </MediaQuery>
        {readyForSub && <ChatSubscription />}
        <Outlet />
      </AppShell>
    </>
  );
};

export const Layout = observer(_Layout);
