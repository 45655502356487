import * as Sentry from "@sentry/react";
import { createTRPCClient, createTRPCClientProxy } from "@trpc/client";
import {
  TRPCClientError,
  createTRPCReact,
  createWSClient,
  inferReactQueryProcedureOptions,
  loggerLink,
  wsLink,
} from "@trpc/react-query";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import type { AppRouter } from "../../../backend/src/router";
import { Session, store } from "../stores/store";

export const trpc = createTRPCReact<AppRouter>({});

export const retryDelay = (attemptIndex: number) => (attemptIndex === 0 ? 0 : Math.min(1000 * attemptIndex, 5000));

export const wsClient = createWSClient({
  retryDelayMs: retryDelay,
  url: `${import.meta.env.VITE_TRPC_ENDPOINT}/api/subscriptions`,
  onOpen: () => {
    store.setConnected(true);
    const token = localStorage.getItem("token");
    if (token) {
      client.auth.authByToken.mutate({ token }).then((res) => {
        if (res.success) {
          store.setAuthorized(true);
          if (res.session) {
            store.setSession(
              new Session({
                userId: res.session.userId,
                email: res.session.email,
                isAdmin: res.session.isAdmin,
                needFillInfo: res.session.needFillInfo,
              })
            );
            Sentry.setUser({
              id: res.session.userId,
              // Excluded because of PII
              // email: res.session.email,
              // username: `${res.session.firstName} ${res.session.lastName}`,
            });
          }
        } else {
          localStorage.removeItem("token");
          window.location.replace("/login");
          Sentry.setUser(null);
        }

        store.setAuthFinished(true);
      });
    } else {
      store.setAuthFinished(true);
    }
  },
  onClose: () => {
    console.log("close");
    store.setConnected(false);
    store.setAuthFinished(false);
  },
});

export const trpcLink = wsLink({ client: wsClient });

export const trpcClient = createTRPCClient<AppRouter>({
  links: [
    loggerLink({
      enabled: (opts) => {
        if (opts.direction === "down") {
          if (opts.result instanceof TRPCClientError) {
            console.error(opts.result);
            if (opts.result.data?.code === "UNAUTHORIZED") {
              // store.setAuthorized(false);
              // localStorage.removeItem("token");
            }
          }
        }
        return false;
      },
    }),
    trpcLink,
  ],
});

export const client = createTRPCClientProxy(trpcClient);

setInterval(() => {
  // keep the connection alive
  if (wsClient.getConnection().readyState === WebSocket.OPEN) {
    wsClient.getConnection().send("[]");
  }
}, 30000);

export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;
export type RouterInputs = inferRouterInputs<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;

export type RequestLimits = RouterOutputs["chat"]["getRequestLimit"];
