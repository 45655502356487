import { Badge, Button, Center, Container, Text } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { PromptInput } from "../components/chat/PromptInput/PromptInput";
import FinamLogo2 from "../icons/FinamLogo2";

export const IndexRoute = observer(() => {
  return (
    <>
      <Center py="xl" sx={{ height: "100%" }}>
        <Container size="sm">
          <Badge mb="lg">GPT-4</Badge>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 16,
              maxWidth: "100%",
              flexWrap: "wrap",
              gap: 32,
            }}
          >
            <FinamLogo2 style={{ maxWidth: 80, marginRight: 24 }} />
            <span style={{ fontSize: 40, color: "#ffc755" }}>Финам.AI</span>
            <Button component="a" style={{}} href="/docs/FinamAI_Guide.pdf" target="blank">
              Скачать гайд <IconDownload style={{ marginLeft: 8 }} />
            </Button>
          </div>
          <Text mt={4} size="xl">
            Общайся c Финам.AI продуктивно – пиши промпт правильно:
          </Text>
          <Text mt={16}>
            Я хочу, чтобы ты помог мне с [тема задачи], хочу, чтобы ты выступал в роли [название или описание роли]. Вот
            описание задачи: [описание здачи, как можно детальнее]. Помоги мне [постановка задачи]. Приведи ответ в
            формате [желаемый формат: список, таблицей и тд].
          </Text>
          <Text mt={16} mb={168}>
            Пример:
            <br />Я хочу, чтобы ты помог мне с написанием текста для лендинга. Хочу, чтобы ты выступал в роли опытного
            маркетолога с экспертизой в написании продающих текстов. Вот описание задачи: лендинг будет для опытных
            инвесторов, рассказывать о преимущствах брокерского счета и нашей компании. Помоги мне написать структуру
            такого лендинга. Ответ приведи в виде списка с детализацией каждого пункта.
          </Text>
        </Container>
      </Center>
      <PromptInput />
    </>
  );
});
