import { MantineReactTable, useMantineReactTable, type MRT_ColumnDef } from "mantine-react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { RouterOutputs, trpc } from "../../../utils/trpc";
import { Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import DateFilterMenuItems from "../../common/DateFilterMenuItems";

type Message = RouterOutputs["admin"]["listFiles"]["files"][0];

const FilesTable = () => {
  const navigate = useNavigate();

  const { data } = trpc.admin.listFiles.useQuery({});

  const columns = useMemo<MRT_ColumnDef<Message>[]>(
    () => [ 
      {
        accessorKey: "id",
        header: "ID файла",
      },
      {
        accessorKey: "createdAt",
        header: "Дата",
        sortingFn: 'datetime',
        accessorFn: ({createdAt}) => new Date(createdAt),
        filterVariant: 'date-range',
        Cell: ({ cell }) => new Date(cell.getValue<number>()).toLocaleString(),
        renderColumnFilterModeMenuItems: ({ column, internalFilterOptions }) => (
          <DateFilterMenuItems
            column={column}
            internalFilterOptions={internalFilterOptions}
          />
        ),
      },
      {
        accessorKey: "filename",
        header: "Название файла",
      },
      {
        accessorKey: "size",
        header: "Размер файла",
        Cell: ({ cell }) => formatFileSize(cell.getValue<number>()),
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
      },
      {
        accessorKey: "user.email",
        header: "Email пользователя",
        Cell: ({ cell }) => (
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => navigate(`/admin/users/${cell.row.original.userId}`)}
          >
            {cell.getValue<string>()}
          </span>
        ),
      },
      {
        accessorKey: "chatId",
        header: "Идентификатор чата",
      },
    ],
    [navigate]
  );

  const table = useMantineReactTable({
    columns,
    data: data?.files || [],
    enableColumnFilterModes: true,
    enableRowActions: true,
    renderRowActions: ({ row }) => {
      const downloadFile = async () => {
        try {
          const response = await fetch(`${import.meta.env.VITE_BACKEND_ENDPOINT}/files/${row.original.id}`, {
            redirect: "follow",
          });

          if (response.redirected) {
            window.location.href = response.url;
          } else {
            throw new Error('Request failed.');
          }
        } catch (error) {
          notifications.show({
            message: "При обработке вашего файла произошла ошибка",
            color: "red",
          });
        }
      };

      return (
        <Button variant="light" onClick={downloadFile}>
          minIO
        </Button>
      );
    },
  });

  return <MantineReactTable table={table} />;
};

function formatFileSize(bytes: number, precision = 2): string {
  const size = Math.abs(bytes);
  const units = ['Bytes', 'KB', 'MB', 'GB'];
  const unitIndex = Math.floor(Math.log(size) / Math.log(1024));
  const fileSize = (size / Math.pow(1024, unitIndex)).toFixed(precision);
  const unit = units[unitIndex];

  return `${bytes < 0 ? '-' : ''}${fileSize} ${unit}`;
}

export default FilesTable;
