import { ChatType } from "./stores/store";

export const supportedFileTypes: Record<ChatType, string[]> = {
  GENERIC: ["application/pdf", "text/plain", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
  AI_CONTEXT: [
    "application/pdf",
    "text/plain",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],
  FLOW: [],
};
