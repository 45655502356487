import { ActionIcon, Box, Code, CopyButton, Table, Tooltip } from "@mantine/core";
import { Prism, PrismProps } from "@mantine/prism";
import { IconCopy } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useChatId } from "../../../hooks/useChatId";
import { store } from "../../../stores/store";
import { IMessage } from "../../../stores/types";

const _MessageContent = ({ message, autoscroll }: { message: IMessage; autoscroll?: boolean }) => {
  const chatId = useChatId();

  useEffect(() => {
    if (autoscroll) {
      window.scrollTo(0, document.documentElement.scrollHeight);
    }
  }, [message.content, autoscroll]);

  if (!message.content) {
    return null;
  }

  return (
    <ReactMarkdown
      children={message.content || ""}
      remarkPlugins={[remarkGfm]}
      components={{
        table: ({ node, ...props }) => <Table verticalSpacing="sm" highlightOnHover {...props} />,
        img: ({ node, ...props }) => {
          const src = props.src?.startsWith("file-service://")
            ? `${props.src.replace("file-service://", `${import.meta.env.VITE_BACKEND_ENDPOINT}/images/`)}`
            : props.src;
          return (
            <div style={{ maxWidth: 400 }}>
              <a href={src} download target="_blank">
                <img style={{ width: "100%" }} src={src} />
              </a>
            </div>
          );
        },
        code: ({ node, inline, ...props }) => {
          const { children, className, ...rest } = props;
          const match = /language-(\w+)/.exec(className || "");
          const language = (match?.[1] || "python") as PrismProps["language"];

          return inline ? (
            <Code {...props} />
          ) : (
            <Box sx={{ position: "relative" }}>
              <Prism language={language}>{String(props.children)}</Prism>
              <CopyButton value={String(props.children)}>
                {({ copied, copy }) => (
                  <Tooltip label={copied ? "Скопировано" : "Копировать"} position="left">
                    <ActionIcon sx={{ position: "absolute", top: 4, right: 4 }} onClick={copy}>
                      <IconCopy opacity={0.4} size={20} />
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Box>
          );
        },
        a: ({ node, ...props }) => {
          const nodeVal = "value" in node.children[0] ? node.children[0].value : null;
          const href = typeof node.properties?.href === "string" ? node.properties.href : null;

          if (href?.startsWith("flow://")) {
            return (
              <a
                onClick={(event) => {
                  const prompt = `Запрашиваю [${nodeVal}](${href})`;
                  event.preventDefault();
                  store.getCompletion({
                    prompt,
                    chatId: chatId,
                  });
                }}
                href="#"
              >
                {props.children}
              </a>
            );
          }

          return <a {...props} target="_blank" rel="noopener noreferrer" />;
        },
      }}
    />
  );
};

export const MessageContent = observer(_MessageContent);
