import { ActionIcon, Button, CopyButton, Modal, Progress, Stack, Text, TextInput, Tooltip } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useChatId } from "../hooks/useChatId";
import { trpc } from "../utils/trpc";

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const _ShareChatModal = (props: IProps) => {
  const { opened, onClose } = props;

  const chatId = useChatId();

  const [loading, setLoading] = useState(false);
  const { mutateAsync: shareChat } = trpc.chat.shareChat.useMutation();
  const { isFetching, data: chatInfo, refetch } = trpc.chat.getChatInfo.useQuery({ chatId: chatId! });
  const [shared, setShared] = useState(false);

  const clipboard = useClipboard();

  const [sharingLoading, setSharingLoading] = useState(false);
  const [shareLink, setShareLink] = useState("");

  useEffect(() => {
    if (chatInfo?.sharedChatId) {
      const link = `${window.location.origin}/share/${chatInfo.sharedChatId}`;
      setShareLink(link);
    }
  }, [chatInfo?.sharedChatId]);

  useEffect(() => {
    if (opened) {
      setShared(false);
      setShareLink("");
    }
    const fetchData = async () => {
      const resp = await refetch();
      if (resp.data?.sharedChatId) {
        const link = `${window.location.origin}/share/${resp.data?.sharedChatId}`;
        setShareLink(link);
      }
    };

    fetchData();
  }, [opened, refetch]);

  if (!chatId) return null;

  return (
    <Modal opened={opened} onClose={onClose} title="Поделиться чатом" size="lg">
      {isFetching && <Progress />}
      {!isFetching && (
        <Stack>
          <Text mb={16}>
            {shareLink ? (
              <Text>
                Вы уже получили ссылку на чат, чтобы поделится историей своего чата, просто отправьте эту ссылку
                коллеге. Если хотите обновить историю диалога доступную по ссылке, нажмите на кнопку "Обновить".
              </Text>
            ) : (
              <Text>
                Нажмите кнопку ниже чтобы получить уникальную ссылку по которой вы сможете поделится историей вашего
                чата с коллегами. По ссылке будут доступны только сообщения которые есть в чате на текущий момент, чтобы
                обновить ссылку, вернитесь в это окно и нажмите кнопку "Обновить".
              </Text>
            )}
          </Text>
          {shareLink && (
            <TextInput
              value={shareLink}
              readOnly
              mb={16}
              rightSection={
                <CopyButton value={shareLink} timeout={2000}>
                  {({ copied, copy }) => (
                    <Tooltip label={copied ? "Скопировано" : "Копировать"} withArrow position="right">
                      <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                        {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                      </ActionIcon>
                    </Tooltip>
                  )}
                </CopyButton>
              }
            />
          )}
          <Button
            style={{ alignItems: "end", alignContent: "end", justifyContent: "end", justifySelf: "end" }}
            loading={sharingLoading}
            onClick={async () => {
              setSharingLoading(true);
              const { sharedChatId } = await shareChat({ chatId });
              const link = `${window.location.origin}/share/${sharedChatId}`;
              setShared(true);
              setShareLink(link);
              clipboard.copy(link);
              setSharingLoading(false);
              notifications.show({
                title: "Ссылка скопирована",
                message: "Ссылка на чат скопирована в буфер обмена",
                color: "green",
              });
            }}
          >
            {shareLink ? "Обновить и скопировать ссылку" : "Скопировать ссылку"}
          </Button>
        </Stack>
      )}
    </Modal>
  );
};

export const ShareChatModal = observer(_ShareChatModal);
