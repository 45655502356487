import { injectStores } from "@mobx-devtools/tools";
import { Unsubscribable } from "@trpc/server/observable";
import { makeAutoObservable, runInAction } from "mobx";
import { RouterOutput } from "../../../backend/src/router";
import { router } from "../main";
import { client, RequestLimits } from "../utils/trpc";
import { IAttachedFile, IMessage } from "./types";

export interface IChat {
  id: string;
  description: string;
  totalTokens: number;
  createdAt: Date;
  updatedAt: Date;
  chatLoaded?: boolean;
  tag?: string | null;
  type: ChatType;
}

export type ChatType = RouterOutput["chat"]["getChats"][0]["type"];

export class Chat implements IChat {
  id: string;
  description: string;
  totalTokens: number;
  createdAt: Date;
  updatedAt: Date;
  chatLoaded: boolean = false;
  tag: string | null = null;
  type: ChatType;

  constructor(props: IChat) {
    makeAutoObservable(this);
    this.id = props.id;
    this.description = props.description;
    this.totalTokens = props.totalTokens;
    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
    this.chatLoaded = props.chatLoaded || false;
    this.tag = props.tag || null;
    this.type = props.type;
  }

  setState = (props: Partial<IChat>) => {
    Object.assign(this, props);
  };

  get canAttachFile() {
    return this.type !== "FLOW";
  }

  get canShare() {
    return this.type === "GENERIC";
  }
}

export class Message implements IMessage {
  id: string;
  chatId: string;
  role: string | null;
  content: string | null;
  createdAt: Date | string;
  toolUsed: string | null = null;
  hidden?: boolean;
  temp = false;
  attachedFiles: IAttachedFile[] = [];

  constructor(props: IMessage) {
    makeAutoObservable(this);
    this.id = props.id;
    this.chatId = props.chatId;
    this.role = props.role;
    this.content = props.content;
    this.createdAt = props.createdAt;
    this.hidden = props.hidden;
    this.temp = props.temp || false;
    this.attachedFiles = props.attachedFiles;
  }

  setState = (props: Partial<IMessage>) => {
    Object.assign(this, props);
  };
}

export interface IPrompt {
  id: string;
  title: string;
  content: string;
  createdAt: Date;
}

interface ISession {
  userId: number;
  email: string;
  isAdmin: boolean;
  needFillInfo: boolean;
}

export class Session implements ISession {
  userId: number;
  email: string;
  isAdmin: boolean;
  needFillInfo: boolean;

  constructor(props: ISession) {
    makeAutoObservable(this);
    this.userId = props.userId;
    this.email = props.email;
    this.isAdmin = props.isAdmin;
    this.needFillInfo = props.needFillInfo;
  }

  setState = (props: Partial<ISession>) => {
    Object.assign(this, props);
  };
}

export class Store {
  prompt = "";
  uploading = false;
  tag: string | null = null;
  limits: RequestLimits | null = null;
  chats!: Chat[];
  messages!: Message[];
  search = "";
  connected = false;
  authorized = false;
  submitting = false;
  isAdmin = false;
  authFinished = false;
  session: Session | null = null;
  chatUpdateSubscription: Unsubscribable | null = null;
  chatsLoaded = false;
  imageModel = "openai/dall-e-3";
  textModel: string = "GENERIC";
  private _appConfig: RouterOutput["chat"]["getAppConfig"] | null = null;
  attachedFiles: { filename: string; fileId: string }[] = [];
  systemPromptOption: string | null = null;
  systemPrompt: string | null = null;

  constructor() {
    makeAutoObservable(this);
    this.chats = [];
    this.messages = [];
  }

  setSystemPrompt = (prompt: string | null) => {
    this.systemPrompt = prompt;
  };

  setSystemPromptOption = (option: string | null) => {
    this.systemPromptOption = option;
  };

  setAttachedFiles = (files: { filename: string; fileId: string }[]) => {
    this.attachedFiles = files;
  };

  getLimits = async () => {
    const limits = await client.chat.getRequestLimit.query();
    runInAction(() => {
      this.limits = limits;
    });
  };

  setPrompt = (prompt: string) => {
    this.prompt = prompt;
  };

  setUploading = (uploading: boolean) => {
    this.uploading = uploading;
  };

  setTag = (tag: string | null) => {
    this.tag = tag;
  };

  loadConfig = async (force?: boolean) => {
    if (this._appConfig && !force) {
      return;
    }
    const config = await client.chat.getAppConfig.query();
    runInAction(() => {
      this._appConfig = config;

      // load from local storage, or use first if not exists

      this.imageModel =
        config.imageModels.find((x) => x.value === localStorage.getItem("imageModel"))?.value ||
        config.imageModels[0].value;

      this.textModel =
        config.textModels.find((x) => x.value === localStorage.getItem("textModel"))?.value ||
        config.textModels[0].value;
    });
  };

  getCompletion = async ({ chatId, prompt }: { chatId?: string; prompt: string }) => {
    const content = prompt;

    if (store.submitting || !content.trim() || this.uploading) {
      return;
    }

    const messages = chatId ? store.getChatMessages(chatId) : [];

    store.setSubmitting(true);
    this.setPrompt("");
    const realMessages = messages.filter((x) => !x.temp);
    const lastMessageId = realMessages[realMessages.length - 1]?.id;

    try {
      const data = await client.chat.getCompletion.mutate({
        chatId: chatId || null,
        message: content,
        parentId: lastMessageId || null,
        chatTag: this.tag,
        attachedFiles: this.attachedFiles.map((x) => x.fileId),
        textModel: store.textModel,
        imageModel: store.imageModel,
        systemPrompt: this.systemPrompt,
      });

      this.setAttachedFiles([]);
      this.getLimits();

      let chat = store.getChatById(data.chatId);

      if (chat) {
        chat.setState({ updatedAt: new Date() });
      }

      if (!chat) {
        chat = new Chat({
          createdAt: new Date(),
          description: "Новый чат",
          id: data.chatId,
          totalTokens: 0,
          chatLoaded: true,
          updatedAt: new Date(),
          tag: this.tag,
          type: this.textModel as ChatType,
        });
        store.addChat(chat);
      }
      if (chat.type !== "FLOW") {
        router.navigate(`/chats/${chat.id}`);
      }
    } catch (error) {
      store.setSubmitting(false);
    }

    this.setTag(null);
  };

  get configLoaded() {
    return !!this._appConfig;
  }

  get appConfig() {
    if (!this._appConfig) {
      throw new Error("App config is not loaded");
    }
    return this._appConfig;
  }

  setTextModel = (textModel: string) => {
    this.textModel = textModel;
    // save to local storage
    localStorage.setItem("textModel", textModel);

    if (textModel !== "AI_CONTEXT") {
      this.attachedFiles = [];
    }
  };

  setImageModel = (imageModel: string) => {
    this.imageModel = imageModel;
    // save to local storage
    localStorage.setItem("imageModel", imageModel);
  };

  setChatsLoaded = (chatsLoaded: boolean) => {
    this.chatsLoaded = chatsLoaded;
  };

  setSession = (session: Session) => {
    this.session = session;
  };

  setAdmin = (isAdmin: boolean) => {
    runInAction(() => {
      this.isAdmin = isAdmin;
    });
  };

  setSubmitting = (submitting: boolean) => {
    runInAction(() => {
      this.submitting = submitting;
    });
  };

  isAuthorized = () => {
    return this.authorized;
  };

  setAuthorized = (authorized: boolean) => {
    runInAction(() => {
      this.authorized = authorized;
    });
  };

  setAuthFinished = (authFinished: boolean) => {
    runInAction(() => {
      this.authFinished = authFinished;
    });
  };

  setConnected = (connected: boolean) => {
    this.connected = connected;
    if (!connected) {
      this.authFinished = false;
    }
  };

  setSearch = (search: string) => {
    this.search = search;
  };

  addMessage = (message: Message) => {
    this.messages.push(message);
  };

  deleteMessageById = (id: string) => {
    this.messages = this.messages.filter((message) => message.id !== id);
  };

  getChatMessages = (chatId: string) => {
    // Ordered by creation date
    return this.messages
      .filter((message) => message.chatId === chatId)
      .slice()
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  };

  getVisibleChatMessages = (chatId: string) => {
    const chat = this.getChatById(chatId);
    return this.getChatMessages(chatId).filter((message) => chat?.type === "AI_CONTEXT" || message.role !== "system");
  };

  getChatById = (id: string) => {
    if (id === "flow") {
      return this.chats.find((x) => x.type === "FLOW");
    }
    return this.chats.find((chat) => chat.id === id);
  };

  addChat = (chat: Chat) => {
    this.chats.push(chat);
  };

  getFirstMessageByChatId = (chatId: string) => {
    return this.getChatMessages(chatId)[0];
  };

  clearMessages = () => {
    this.messages = [];
  };

  deleteChatById = (id: string) => {
    this.chats = this.chats.filter((chat) => chat.id !== id);
  };

  cleanChats = () => {
    this.chats = [];
  };

  get getOrderedChats() {
    return this.chats
      .slice()
      .filter((x) => x.type !== "FLOW")
      .sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());
  }
}

export const store = new Store();

injectStores({
  store,
});
